module.exports = {
  messages: {
    CN: {
      backhome: '返回首页',
      description: '这是一个演示页面'
    },
    HK: {
      backhome: '演示頁面',
      description: '這是一個演示頁面'
    },
    US: {
      backhome: 'back home',
      description: 'This is a demo page'
    }
  }
}